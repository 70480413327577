<template>
  <v-row class="smpp">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">smpp api</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="text-uppercase text-h5 font-weight-bold">Access settings</div>
        <div class="px-sm-10 pt-sm-5 pb-sm-10">
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-h6 text-md-left text-center">{{ data.enabled ? 'Disable' : 'Enable' }} SMPP API access</div>
              <div class="opasity--text text-md-left text-center mt-3">
                SMPP API is a good integration option for high-frequency SMS sending
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-md-right text-center mb-sm-0 mb-4">
                <v-btn
                  @click="activate(true, data.restrict_by_allowed_ips)"
                  elevation="0"
                  v-if="!data.enabled"
                  width="180"
                  height="48"
                  color="primary"
                >
                  Enable
                </v-btn>
                <v-btn @click="activate(false)" elevation="0" v-else width="180" height="48">Disable</v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <div class="px-sm-10 pt-sm-5 pb-sm-10">
          <v-row>
            <v-col cols="12" sm="8">
              <div class="text-h6 text-sm-left text-center">Sandbox API</div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-md-right text-center">
                <v-hover v-slot="{ hover }">
                  <div class="detector link white--text" :class="data.sandbox.allowed ? 'success' : 'error'">
                    {{ data.sandbox.allowed ? 'Allowed' : 'Not allowed' }}
                    <v-fade-transition>
                      <div v-if="hover" class="accent pa-3 text-left rounded mt-3 status-info">
                        <div class="d-flex justify-space-between align-center">
                          <div class="f14">Allowed</div>
                          <div class="success rounded-circle" style="width: 8px; height: 8px"></div>
                        </div>
                        <div class="f14 opasity--text">The API access is allowed and can be used</div>
                        <v-divider class="white"></v-divider>
                        <div class="d-flex justify-space-between align-center">
                          <div class="f14">Not Allowed</div>
                          <div class="error rounded-circle" style="width: 8px; height: 8px"></div>
                        </div>
                        <div class="f14 opasity--text">The API access in not allowed. Please contact the support to obtain he access</div>
                      </div>
                    </v-fade-transition>
                  </div>
                </v-hover>
              </div>
            </v-col>
          </v-row>
          <!-- <div class="d-flex">
            <div>
              <v-row class="mt-5">
                <v-col cols="6">
                  <v-text-field
                    v-model="data.sandbox.system_id"
                    label="System ID"
                    filled
                    outlined
                    dense
                    required
                    color="input"
                    :disabled="!data.enabled"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="6">
                  <v-text-field
                    v-model="data.sandbox.password"
                    label="Password"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    filled
                    outlined
                    dense
                    required
                    color="input"
                    :disabled="!data.enabled"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    :disabled="!data.enabled"
                    @click="generate('sandbox')"
                    class="ml-5"
                    elevation="0"
                    width="184"
                    height="48"
                    color="secondary"
                  >
                    Generate new
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div>test</div>
          </div> -->
          <v-row no-gutters class="mt-5">
            <v-col cols="12" md="5">
              <v-text-field
                v-model="data.sandbox.system_id"
                label="System ID"
                filled
                outlined
                dense
                required
                color="input"
                :disabled="!data.enabled"
              ></v-text-field>
              <v-text-field
                v-model="data.sandbox.password"
                label="Password"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                filled
                outlined
                dense
                required
                color="input"
                :disabled="!data.enabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" align-self="end" class="text-center">
              <v-btn
                :disabled="!data.enabled"
                @click="(genApi = !genApi), (type = 'sandbox')"
                class="ml-md-5 mb-26"
                elevation="0"
                width="184"
                height="48"
                color="secondary"
              >
                Generate new
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="text-md-right text-center mb-sm-0 mb-4">
              <div>
                <span class="text-left font-weight-medium stat-field">Max Binds</span>
                <span class="text-left desc-field">{{ data.sandbox.max_binds }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Inbound window</span>
                <span class="text-left desc-field">{{ data.sandbox.inbound_window }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Outbound window</span>
                <span class="text-left desc-field">{{ data.sandbox.outbound_window }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Inbound rate (m/s)</span>
                <span class="text-left desc-field">{{ data.sandbox.inbound_rate_limit }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Outbound rate (m/s)</span>
                <span class="text-left desc-field">{{ data.sandbox.outbound_rate_limit }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <div class="px-sm-10 pt-sm-5 pb-sm-10">
          <v-row>
            <v-col cols="12" md="8">
              <div class="text-h6 text-md-left text-center">Live API</div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="text-md-right text-center">
                <v-hover v-slot="{ hover }">
                  <div class="detector link white--text" :class="data.live.allowed ? 'success' : 'error'">
                    {{ data.live.allowed ? 'Allowed' : 'Not allowed' }}
                    <v-fade-transition>
                      <div v-if="hover" class="accent pa-3 text-left rounded mt-3 status-info">
                        <div class="d-flex justify-space-between align-center">
                          <div class="f14">Allowed</div>
                          <div class="success rounded-circle" style="width: 8px; height: 8px"></div>
                        </div>
                        <div class="f14 opasity--text">The API access is allowed and can be used</div>
                        <v-divider class="white"></v-divider>
                        <div class="d-flex justify-space-between align-center">
                          <div class="f14">Not Allowed</div>
                          <div class="error rounded-circle" style="width: 8px; height: 8px"></div>
                        </div>
                        <div class="f14 opasity--text">The API access in not allowed. Please contact the support to obtain he access</div>
                      </div>
                    </v-fade-transition>
                  </div>
                </v-hover>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row class="mt-5">
            <v-col cols="6">
              <v-text-field
                v-model="data.live.system_id"
                label="System ID"
                filled
                outlined
                dense
                required
                color="input"
                :disabled="!data.enabled"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="6">
              <v-text-field
                v-model="data.live.password"
                label="Password"
                :type="showL ? 'text' : 'password'"
                :append-icon="showL ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showL = !showL"
                filled
                outlined
                dense
                required
                color="input"
                :disabled="!data.enabled"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn
                :disabled="!data.enabled"
                @click="generate('live')"
                class="ml-5"
                elevation="0"
                width="184"
                height="48"
                color="secondary"
              >
                Generate new
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row no-gutters class="mt-5">
            <v-col cols="12" md="5">
              <v-text-field
                v-model="data.live.system_id"
                label="System ID"
                filled
                outlined
                dense
                required
                color="input"
                :disabled="!data.enabled"
              ></v-text-field>
              <v-text-field
                v-model="data.live.password"
                label="Password"
                :type="showL ? 'text' : 'password'"
                :append-icon="showL ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showL = !showL"
                filled
                outlined
                dense
                required
                color="input"
                :disabled="!data.enabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" align-self="end" class="text-center">
              <v-btn
                :disabled="!data.enabled"
                @click="(genApi = !genApi), (type = 'live')"
                class="ml-md-5 mb-26"
                elevation="0"
                width="184"
                height="48"
                color="secondary"
              >
                Generate new
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="text-md-right text-center mb-sm-0 mb-4">
              <div>
                <span class="text-left font-weight-medium stat-field">Max Binds</span>
                <span class="text-left desc-field">{{ data.live.max_binds }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Inbound window</span>
                <span class="text-left desc-field">{{ data.live.inbound_window }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Outbound window</span>
                <span class="text-left desc-field">{{ data.live.outbound_window }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Inbound rate (m/s)</span>
                <span class="text-left desc-field">{{ data.live.inbound_rate_limit }}</span>
              </div>
              <div>
                <span class="text-left font-weight-medium stat-field">Outbound rate (m/s)</span>
                <span class="text-left desc-field">{{ data.live.outbound_rate_limit }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <div class="px-sm-10 pt-sm-5">
          <v-checkbox
            @change="activate(data.enabled, data.restrict_by_allowed_ips)"
            class="mt-0"
            hide-details
            color="success"
            v-model="data.restrict_by_allowed_ips"
            :disabled="!data.enabled"
          >
            <template v-slot:label>
              <div class="text-h6">Restrict access by IP addresses</div>
            </template>
          </v-checkbox>
          <v-divider class="mt-2"></v-divider>
          <v-data-table
            no-data-text="Provide a list of IPs that allowed to connect to the API Both IPv4 and IPv6 addresses are supported"
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="tableItems"
          >
            <template v-slot:item.delete="{ item }">
              <v-btn :disabled="!data.enabled" class="rounded" text icon tile elevation="0" @click="openDelete(item)">
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-btn :disabled="!data.enabled" class="mt-2" height="48" text color="input" @click="addIpModal = !addIpModal">
                <v-icon class="input rounded-circle btn-icon" color="white" left>mdi-plus mdi-24px</v-icon>
                Add IP
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-dialog v-model="genApi" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Generate New</div>
          <div class="mt-3 opasity--text">
            Do you really want to generate a new SMPP credentials? The current SMPP credentials will be deactivated and all active sessions
            will be terminated
          </div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="genApi = !genApi">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="generate()">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteIp" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Delete IP</div>
          <div class="mt-3 opasity--text">Do you really want to delete {{ editedItem.ip }}?</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="deleteItem">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addIpModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add new variable</div>
          <v-text-field
            v-model="ip"
            label="IP"
            :error-messages="ipErrors"
            placeholder="Enter IP v4 or v6"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="addIp">Add</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="addIpModal = !addIpModal">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    ip: { required },
  },
  data() {
    return {
      load: true,
      show: false,
      showL: false,
      genApi: false,
      addIpModal: false,
      deleteIp: false,
      editedItem: {},
      type: null,
      ip: null,
      headers: [
        { text: 'IP (v4/v6)', value: 'ip', sortable: false },
        { text: 'Delete', value: 'delete', align: 'end', sortable: false },
      ],
      tableItems: [],
      error: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'add') {
        this.$notify({ message: 'New IP add', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'newKey') {
        this.$notify({ message: 'Generate new secret key', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'delete') {
        this.$notify({ message: 'IP delete', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async getData() {
      await this.$store.dispatch('getSmpp').finally(() => {
        this.load = false;
      });
    },
    async activate(type, check) {
      const data = new Object();
      data.enabled = type;
      data.restrict_by_allowed_ips = check;
      await this.$store.dispatch('updateSmpp', data);
    },
    async generate() {
      const data = new Object();
      data.type = this.type;
      await this.$store
        .dispatch('newKeySmpp', data)
        .then(() => {
          this.notifi('newKey');
          this.genApi = !this.genApi;
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
        });
    },
    openDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.deleteIp = true;
    },
    closeDialog() {
      this.addIpModal = false;
      this.deleteIp = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },

    async addIp() {
      this.$v.ip.$touch();
      if (!this.$v.ip.$invalid) {
        this.error = null;
        const data = new Object();
        data.ip = this.ip;
        await this.$store
          .dispatch('addIpSmpp', data)
          .then(() => {
            this.closeDialog();
            this.notifi('add');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
          });
      }
    },
    async deleteItem() {
      const data = new Object();
      data.ip = this.editedItem.ip;
      await this.$store.dispatch('deletIpSmpp', data).then(() => {
        this.notifi('delete');
        this.closeDialog();
      });
    },
  },
  watch: {
    deleteIp(val) {
      val || this.closeDialog();
    },
    addIpModal() {
      this.ip = null;
      this.$v.ip.$reset();
    },
    data() {
      this.tableItems = [];
      for (var key in this.data.allowed_ips) {
        this.tableItems.push({
          ip: this.data.allowed_ips[key],
        });
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.smpp;
    },
    ipErrors() {
      const errors = [];
      if (!this.$v.ip.$dirty) {
        return errors;
      }
      !this.$v.ip.required && errors.push('Please enter new IP');
      this.error == 'ip__invalid' && errors.push('Provided IP is not valid');
      this.error == 'ip__already_exist' && errors.push('Provided IP already exist');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setSmpp', {
      live: {},
      sandbox: {},
    });
  },
};
</script>

<style lang="scss">
.smpp {
  tbody tr {
    height: 52px;
  }
  .status-info {
    position: absolute;
    left: 50%;
    z-index: 9999;
    width: 304px;
  }
  .detector {
    display: inline-block;
    line-height: 36px;
    width: 164px;
    height: 36px;
    text-align: center;
    border-radius: 24px;
  }
  .stat-field {
    display: inline-block;
    width: 100%;
    max-width: 180px;
  }
  .desc-field {
    display: inline-block;
    width: 100%;
    max-width: 40px;
  }
  .btn-icon {
    width: 32px !important;
    height: 32px !important;
  }
  @media screen and (max-width: 649px) {
    .status-info {
      left: calc(50% - 152px);
    }
  }
}
</style>
